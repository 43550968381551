<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="DynamicProductsLog">
      <template v-slot:right-end>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-8">
          <vx-card>

            <div class="vx-col w-full mt-3" v-if="showMangerDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
                <span
                  @click="selectedManager = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-2 w-full pb-3"
                v-model="selectedManager"
                @input="filterChanged(-1)"
                :placeholder="$t('Manager')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                                v-for="(customer,index) in usersRaw.manager_accounts"/>
              </vs-select>
            </div>


            <div class="vx-col w-full mt-3" v-if="showRefererDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
                <span
                  @click="selectedReferer = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-2 w-full pb-3"
                v-model="selectedReferer"
                @input="filterChanged"
                :placeholder="$t('Staff')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                v-for="(customer,index) in staffAccounts"/>
              </vs-select>
            </div>


            <div class="vx-col mt-4 m-3" v-for="(item, index) in Object.keys(salesFilters)">
              <label style="font-size: 12px; font-weight: bold">
                {{ item | title }}
                <span
                  @click="filterSelectData[item] = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <ul class="centerx mt-4">
                <li v-for="(option, index) in salesFilters[item]">
                  <vs-radio v-model="filterSelectData[item]" v-on:change="filterChanged"
                            :vs-value="option.id.toString() + item">{{ option.name | title }}
                  </vs-radio>
                </li>
              </ul>
              <vs-divider/>
            </div>
            <!--            From TO Date Filters -->
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate"
                        :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>


            <vs-divider/>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="sales.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>

          </transition>
          <vs-table-modified v-show="sales.length > 0 && !errorFetching" ref="table"
                             :max-items="vLimit"
                             :data="sales"
          >

            <template slot="thead">
              <vs-th style="max-width: 72px;">{{ $t('DateAndTime') }}</vs-th>
              <vs-th>{{ $t('CustomerName') }}</vs-th>
              <vs-th>{{ $t('Organisation') }}</vs-th>
              <vs-th>{{ $t('ProductName') }}</vs-th>
              <vs-th>{{ $t('Denomination') }}</vs-th>
              <vs-th>{{ $t('Quantity') }}</vs-th>
              <vs-th>{{ $t('APIName') }}</vs-th>
              <vs-th style="width: 150px;">{{ $t('Status') }}</vs-th>
<!--              <vs-th>{{ $t('Response') }}</vs-th>-->
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="font-medium" style="max-width: 100px; word-break: normal !important;">
                    {{ tr.created | dateFormat }}
                  </p>
                </vs-td>

                <vs-td>
                  <div>
                    <p class="product-name font-medium truncate">{{ tr.user }}</p>
                    <p class="product-name font-medium truncate" style="font-size: 12px;">{{ tr.customer_id }}</p>
                  </div>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.organisation }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.product_name }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.denomination | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.quantity }}</p>
                </vs-td>
                <vs-td>

                  <div>
                    <p class="product-name font-medium">{{ tr.service | title }}</p>
                    <p
                      class="product-name font-medium"
                      v-if="tr.service && tr.service.indexOf('E-Pay') !== -1 || tr.product_type == 2"
                      style="font-size: 12px;">{{ tr.terminal_id }}</p>
                  </div>
                </vs-td>

                <vs-td style="width: 100px;" >

                  <div class="vs-col" style="display: inline-grid;" @click="showServerResModal(tr)">
                    <vs-chip class="vs-col" :color="tr.status == 0 ? 'danger' : 'success'">{{ tr.status == 0 ? 'Failed' : 'Success' }}
                    </vs-chip  >
                    <a class="vs-col" style="color: rgb(var(--vs-primary))">View response</a>
                  </div>
                </vs-td>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>


    <vx-modal v-if="showServerResponse" component-class="modal-container-7">
      <template slot="body">
        <div style="max-height: 500px; overflow-y: auto;">
          <div class="text-center status-card">
            <p>{{ serverResponse }}</p>
            <vs-button class="ml-4 mt-3" color="primary" @click="closeModal()">{{ $t('Close') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: 'DynamicProductsLog',
  components: {
    VxModal,
    Datepicker,
    DynamicCustomersDropDown,
  },
  data() {
    return {
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Dynamic Products Log', i18n: 'DynamicProductsLog', active: true},
      ],
      dynamicType: ['Soft API', 'EPay', 'International Top-Up', 'Direct Recharge', 'Gift card'],
      filterSelectData: {},
      selectedProduct: null,
      selectedCustomer: null,
      selectedProductConfigId: null,
      startDate: new Date(),
      endDate: new Date(),
      customerFilter: [],
      customer: {},
      showServerResponse: false,
      serverResponse: "",
      selectedManager: null,
      selectedReferer: null,
      viewAll: false,
    }
  },
  computed: {

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
    sales() {
      return this.$store.state.reports.sales
    },
    productsFilter() {
      return this.$store.state.reports.productsFilter
    },
    vLimit() {
      return this.$store.state.reports.itemCount
    },
    salesRaw() {
      return this.$store.state.reports.salesRaw
    },
    salesFilters() {
      return this.$store.state.reports.typeOfFilters
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.sales.length
    },
    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    users() {
      return this.$store.state.user.users
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
  },
  methods: {
    showServerResModal(res) {
      this.serverResponse = res.server_response;
      this.showServerResponse = true;
    },
    closeModal() {
      this.showServerResponse = false;
      this.serverResponse = ""
    },
    performDownloadSales() {
      this.downloadSales(false, this.getFilters())
    },
    onCustomerFilterChanged(c) {
      this.customer = c
      this.filterChanged()
    },
    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    onCustomerTextChanged(value) {
      const search_text = value.srcElement.value
      const filter = {
        search_text,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/searchCustomers', {filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.customerFilter = data.customers
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    onProductFilterChanged() {
      this.filterChanged()
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.endDate = null
      this.selectedProduct = null
      this.selectedProductConfigId = null
      this.customer = {}
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      if (Object.keys(this.customer).length > 0) {
        filter.customer_id = this.customer.customer_id
      }
      if (this.selectedManager != null) {
        filter.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer != null) {
        filter.refer_id = this.selectedReferer
      }
      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      return filter
    },
    filterChanged(resetRefer) {
      if(resetRefer === -1) {
        this.selectedReferer = null
      }
      this.fetchSales('0', this.getFilters())
    },

    listPageChanged(item) {
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    fetchSales(page = '0', filter = {}, isDownload = false) {
      filter = {
        // 'inventory_type': 1,
        ...filter
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchDynamicProductsLog', {page, filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.fetchSales()
    this.loadReferer()
  },
}
</script>

<style lang="scss">

#sales-reports-view {

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }

    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-warning), 1);
    }
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 200px;
    }


    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
